export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Anime Reborn",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.youtube.com/embed/2tVHh4oxUgM?si=zoHBBKHzg0xAnWCF",
    domain: "animereborn.org",
    gaId: "G-B55D6NHC0G",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
